import React, { Component } from "react"
import { Link } from "gatsby";
import { connect } from "react-redux"

import leftarrow from "../images/left-arrow.svg"

/* const mysteps = {
    nds1: 1,
    nds2: 2,
    nds3: 3,
    nds4: 4,
    nds5: 5
} */
const mysteps = {
    sc1: 1,
    sc3: 2,
    sc5: 3
}
class scstep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
        };
    }

    componentDidMount() {
        
        for (let key in mysteps) {
            if (window.location.pathname.includes(key)) {
                this.setState({ currentStep: mysteps[key] });
            }
        }
        this.nameInput.focus();
    }

    render() {
        const { currentStep } = this.state;
        //console.log('currentStep:'+currentStep)
        const { step2response } = this.props;
        // const backnavigation = currentStep > 1 && WIZARD_STEPS_NUMBER_WISE[currentStep - 1];
        let backnavigation = 'vehicleInfo';
        if (currentStep > 1) {
            Object.keys(mysteps).forEach((key) => {
                if (mysteps[key] === currentStep - 1) {
                    backnavigation = key;
                }
            });
        }
        let recordId = '';
        if (step2response && step2response.recordId) {
            recordId = `/?q=${step2response.recordId}`;
        }
        return (
            <div className="wizrd-container">
                <input className="hidox" ref={(input) => { this.nameInput = input; }} />
                {
                    currentStep !== 1 && currentStep !== 6 &&
                    <Link to={`/${backnavigation}${recordId}`} state={{ isFromWizard: true }} >
                        <span className="left-arrow"><img src={leftarrow} alt={'leftarrow'} /></span>
                    </Link>
                }
                <ul className="scstep">
                <li className={currentStep === 1 ? 'active' : currentStep !== 6 ? 'complete' : ''} >
                        {currentStep !== 6 ?
                            <Link to={`/sc1`} state={{ isFromWizard: true }} >
                                <label>PRODUCER</label>
                                <span>1</span>
                            </Link>
                            :
                            <>
                                <label>PRODUCER</label>
                                <span>1</span>
                            </>
                        }
                    </li>
                    <li className={currentStep === 2 ? 'active' : currentStep > 2 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 2 && currentStep !== 6
                                ?
                                <Link to={`/sc3`} state={{ isFromWizard: true }} >
                                    <label>{'PRODUCTS'}</label>
                                    <span>2</span>
                                </Link>
                                :
                                <>
                                    <label>{'PRODUCTS'}</label>
                                    <span>2</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 3 ? 'active' : currentStep > 3 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 3 && currentStep !== 6
                                ?
                                <Link to={`/sc5`} state={{ isFromWizard: true }} >
                                    <label>SIGN</label>
                                    <span>3</span>
                                </Link>
                                :
                                <>
                                    <label>SIGN</label>
                                    <span>3</span>
                                </>
                        }

                    </li>
                </ul>
                <span className="line nds step5"></span>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        step2response: state.register.step2response
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(scstep);
